import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { NIL as NIL_UUID } from 'uuid';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { Subscription } from 'rxjs';
import { RouteService } from '../../../service/route.service';
import { ADMIN_API_SUCCESS_CODE } from '../../shared/constants/values';
import timezones from '../../../../../timezones.json';

export interface PracticeMasterData {
  id: string;
  pmsName: string;
  name: string;
  internalPracticeId: string;
  sequenceId: string;
  address: string;
  contactPerson: string;
  licenseKey: string;
  // pmsAgentVersion: string;
  statusName: string;
  isInstalled: boolean;
  // credential: string;
  // hasCertificate: boolean;
  // certificateName: string;
  pmsVersion: string;
  isActive: boolean;
  pmsStatusCode: number;
  pmsId: string;
  timezone: string;
}
@Component({
  selector: 'app-practice-master',
  templateUrl: './practice-master.component.html',
  styleUrl: './practice-master.component.scss',
})
export class PracticeMasterComponent {
  @ViewChild(DrawerComponent)
  drawerComponent!: DrawerComponent<PracticeMasterData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  gridConfig: GridConfig = {
    columns: [
      {
        dataType: 'toggle',
        jsonKey: 'isActive',
        label: 'Active',
        isSortable: false,
      },
      { dataType: 'string', jsonKey: 'practiceCode', label: 'Practice Code' },
      { dataType: 'string', jsonKey: 'name', label: 'Practice Name' },
      {
        dataType: 'string',
        jsonKey: 'pmsAgentVersion',
        label: 'PMS Agent Version',
      },
      {
        dataType: 'string',
        jsonKey: 'internalPracticeId',
        label: 'Internal Practice Id',
      },
      { dataType: 'string', jsonKey: 'sequenceId', label: 'Sequence Id' },
      {
        dataType: 'string',
        jsonKey: 'timezone',
        label: 'Time Zone',
        isSortable: false,
      },
      { dataType: 'string', jsonKey: 'pmsName', label: 'PMS Name' },
      {
        dataType: 'string',
        jsonKey: 'pmsVersion',
        label: 'Configured PMS Version',
        isSortable: false,
      },
      {
        dataType: 'string',
        jsonKey: 'latestPMSVersion',
        label: 'Latest PMS Version',
      },
      {
        dataType: 'string',
        jsonKey: 'machineKey',
        label: 'MAC Address',
        isSortable: false,
      },
      // { dataType: 'string', jsonKey: 'address', label: 'Address' },
      // { dataType: 'string', jsonKey: 'contactPerson', label: 'Contact Person' },
      {
        dataType: 'string',
        jsonKey: 'licenseKey',
        label: 'License Key',
        isSortable: false,
      },
      // { dataType: 'string', jsonKey: 'statusName', label: 'Status' },
      { dataType: 'boolean', jsonKey: 'isInstalled', label: 'Installed' },
      // { dataType: 'password', jsonKey: 'credential', label: 'Credential' },
      //{ dataType: 'boolean', jsonKey: 'hasCertificate', label: 'Has Certificate'},
      //{ dataType: 'string', jsonKey: 'certificateName', label: 'Certificate' },
      {
        dataType: 'date',
        jsonKey: 'installationDate',
        label: 'Installation Date',
      },
      {
        label: 'Last Load Status Code',
        jsonKey: 'lastLoadStatusCode',
        dataType: 'number',
        isSortable: false,
      },
      {
        label: 'Last Load Status',
        jsonKey: 'lastLoadStatus',
        dataType: 'string',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Last Load Message',
        jsonKey: 'lastLoadMessage',
        dataType: 'string',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Last Load Date',
        jsonKey: 'lastLoadDate',
        dataType: 'date',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Notes',
        jsonKey: 'notes',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
    ],
    deletable: false,
    editable: true,
    exportable: true,
    displayAdd: true,
    isToggleDisabled: true,
    displayHideAuditColumnsToggle: true,
  };

  getDataUrl = API_ENDPOINTS.PRACTICES;
  addDataUrl = API_ENDPOINTS.PRACTICES;
  editDataUrl = API_ENDPOINTS.PRACTICE_BY_ID;
  toggleUrl = API_ENDPOINTS.TOGGLE_PRACTICE;

  drawerInputConfig: DrawerInputConfig[] = [
    {
      dataType: 'string',
      jsonKey: 'practiceCode',
      input: 'text',
      label: 'Practice Code',
      isRequired: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /^[A-Z0-9]+$/,
    },
    {
      dataType: 'string',
      jsonKey: 'name',
      label: 'Practice Name',
      input: 'text',
      isRequired: true,
      minLength: 1,
      maxLength: 500,
    },
    {
      dataType: 'string',
      jsonKey: 'internalPracticeId',
      label: 'Internal Practice ID',
      input: 'text',
      isRequired: true,
      minLength: 1,
      maxLength: 500,
    },
    {
      dataType: 'string',
      jsonKey: 'sequenceId',
      label: 'Sequence Id',
      input: 'text',
      isRequired: true,
      minLength: 1,
      maxLength: 500,
    },
    // { dataType:'string',jsonKey:'timezone',input:'select',label:'Timezone',isRequired:true,dropdownSortDirection:'asc',foreignKeyProperty:'text',dropdownJsonKey:'text'},
    {
      dataType: 'string',
      jsonKey: 'pmsName',
      label: 'PMS Name',
      input: 'select',
      dropDownOptionsURL: API_ENDPOINTS.PMS_MASTER,
      dropdownJsonKey: 'pmsNameVersion',
      isRequired: true,
      dropdownSortDirection: 'asc',
      foreignKeyProperty: 'pmsId',
    },
    {
      dataType: 'string',
      jsonKey: 'timezone',
      label: 'Time Zone',
      input: 'select',
      isRequired: false,
      dropdownSortDirection: 'asc',
      dropdownJsonKey: 'name',
      isDropDownSearchable: true,
      dropDownOptionsURL: API_ENDPOINTS.TIMEZONE,
    },
    // {dataType:'string',jsonKey:'statusName',label:'Status',input:'select',dropDownOptionsURL:API_ENDPOINTS.PMS_STATUS,dropdownJsonKey:'description',isRequired:true,foreignKeyProperty:'PMSStatusCode',tabIndex:7,uniqueIdentifier:"PracticeMasterStatusDropDown"},
    // {dataType:'string',jsonKey:'address',label:'Address',input:'text',isRequired:true,minLength:1,validationRegex:/^[a-zA-Z0-9 .,/-]+$/},
    // {dataType:'string',jsonKey:'contactPerson',label:'Contact Person',input:'text',isRequired:true,minLength:1,maxLength:1000,validationRegex:/^[a-zA-Z ]+$/},
    // {dataType:'string',jsonKey:'pmsAgentVersion',label:'Agent Version',dropDownOptionsURL:API_ENDPOINTS.PMS_AGENT_RELEASE,dropdownJsonKey:'version',input:'select',isRequired:true,tabIndex:8},
    // {dataType:'boolean',jsonKey:'isInstalled',label:'Installed',input:'toggle',tabIndex:9},
    // {dataType:'string',jsonKey:'credential',label:'Credential',input:'password',isRequired:true,tabIndex:5},
    {
      dataType: 'string',
      jsonKey: 'licenseKey',
      label: 'License Key',
      input: 'text',
      isRequired: true,
      minLength: 7,
      maxLength: 7,
      validationRegex: /^\d+$/,
      isAutoGenerated: true,
      isDisabled: true,
    },
    {
      label: 'Notes',
      jsonKey: 'notes',
      input: 'textarea',
      dataType: 'string',
      isRequired: false,
      minLength: 1,
    },
    {
      dataType: 'boolean',
      jsonKey: 'isActive',
      label: 'Active',
      input: 'toggle',
    },
    // {dataType:'boolean',jsonKey:'hasCertificate',label:'Has Certificate',input:'toggle',tabIndex:11},
    // {dataType:'string',jsonKey:'certificateName',label:'Certificate',input:'text',tabIndex:12},
  ];

  drawerModel: PracticeMasterData = {
    id: null,
    address: '',
    // pmsAgentVersion:'Select Agent version',
    // certificateName:'',
    sequenceId: '',
    contactPerson: '',
    // credential:'',
    // hasCertificate:false,
    isActive: true,
    isInstalled: false,
    pmsVersion: '',
    licenseKey: '',
    name: '',
    internalPracticeId: '',
    pmsName: 'Select PMS',
    statusName: 'Select Status',
    pmsStatusCode: ADMIN_API_SUCCESS_CODE,
    pmsId: '',
    timezone: '',
  };

  private _routerSubscription: Subscription;
  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }
  public set routerSubscription(value: Subscription) {
    this._routerSubscription = value;
  }
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/practices')
          );
        }
        this.cdr.detectChanges();
      });
  }

  onAddHandler(): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  resetEntry() {
    this.drawerModel = {
      id: null,
      address: '',
      // pmsAgentVersion:'Select Agent version',
      // certificateName:'',
      contactPerson: '',
      sequenceId: '',
      // credential:'',
      // hasCertificate:false,
      isActive: true,
      isInstalled: false,
      pmsVersion: '',
      licenseKey: '',
      name: '',
      internalPracticeId: '',
      pmsName: 'Select PMS',
      statusName: 'Select Status',
      pmsStatusCode: ADMIN_API_SUCCESS_CODE,
      pmsId: '',
      timezone: '',
    };
  }

  onEditHandler(rowData: PracticeMasterData): void {
    rowData.pmsName = rowData.pmsName + ' ' + rowData.pmsVersion;
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      this.drawerComponent.drawerModel = rowData;
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }
}
