<main class="w-full bg-white shadow-sm">
  <!-- grid component -->
  <app-dynamic-grid
    [gridConfig]="gridConfig"
    [getDataUrl]="getDataUrl"
    [toggleUrl]="toggleUrl"
    [sortBy]="'modifiedAt'"
    [sortOrder]="'desc'"
    (addClicked)="onAddHandler()"
    (editClicked)="onEditHandler($event)"
    (onToggleShowToastEmitter)="showToast($event)"
  ></app-dynamic-grid>

  <!-- add/edit pms master entry drawer -->
  <app-drawer
    #drawerComponent
    [drawerModel]="drawerModel"
    [drawerConfig]="drawerInputConfig"
    [AddDataURL]="addDataUrl"
    [editDataURL]="editDataUrl"
    [removeLevel]="false"
    [maxDrawerHeight]="'max-h-[450px]'"
    (refresh)="refreshHandler()"
    [createDrawerTitle]="'Create New Practice'"
    [updateDrawerTitle]="'Update Practice'"
    [hasTwoColumns]="true"
    (resetData)="resetEntry()"
  ></app-drawer>
  <app-toast></app-toast>
</main>
