import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EventType, NavigationEnd, Router, Scroll } from '@angular/router';
import { PracticeLoadDataService } from '../practice-load-detail/data/practice-load-data.service';
import { RouteService } from '../../../service/route.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ACKNOWLEDGEABLE_ERROR_STATUS_CODES, MAX_INT_32 } from '../../shared/constants/values';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
@Component({
  selector: 'app-practice-log',
  templateUrl: './practice-log.component.html',
  styleUrl: './practice-log.component.scss',
})
export class PracticeLogComponent {
  toggleUrl: string;
  showPracticeLoadDetail: boolean = false;
  routes: string[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private practiceLoadData: PracticeLoadDataService,
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Acknowledge',
        jsonKey: 'isAcknowledged',
        dataType: 'toggle',
        toggleUrl: API_ENDPOINTS.PRACTICE_LOG_ACKNOWLEDGEMENT,
        isSortable: false,
      },
      { label: 'Practice Code', jsonKey: 'practiceCode', dataType: 'string' },
      {
        dataType: 'text-wrap',
        label: 'Practice Name',
        jsonKey: 'practiceName',
      },
      {
        dataType: 'string',
        label: 'PMS Agent Version',
        jsonKey: 'pmsAgentVersion',
        isSortable: false,
      },
      { dataType: 'string', label: 'PMS Name', jsonKey: 'pmsName' },
      {
        dataType: 'string',
        label: 'Configured PMS Version',
        jsonKey: 'pmsVersion',
        isSortable: false,
      },
      {
        dataType: 'string',
        label: 'Latest PMS Version',
        jsonKey: 'latestPMSVersion',
        isSortable: false,
      },
      { dataType: 'string', label: 'MAC Address', jsonKey: 'machineKey',isSortable:false },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'number' },
      {
        dataType: 'string',
        label: 'PMS Agent Status',
        jsonKey: 'pmsAgentStatus',
        isSortable:false
      },
      {
        dataType: 'text-wrap',
        label: 'Message',
        jsonKey: 'message',
        isSortable: false
      },
      {
        label: 'Resolution',
        jsonKey: 'resolution',
        dataType: 'string',
        width:'full',
        isSortable: false,
      },
      //{ dataType: 'string', label: 'Status', jsonKey: 'pmsStatusName' },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        dataType: 'date',
        label: 'Created At',
        jsonKey: 'createdAt',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],
    filters: [
      {
        label: 'Practice',
        jsonKey: 'practiceId',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
      {
        label: 'Status',
        jsonKey: 'status',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],
    displayHideAuditColumnsToggle: true,
    editable: false,
    deletable: false,
    showAllPagination: true,
  };

  getDataUrl: string = API_ENDPOINTS.PRACTICE_LOG;
  getPracticesUrl = API_ENDPOINTS.PRACTICES;
  getStatusUrl = API_ENDPOINTS.PMS_STATUS;

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  ngOnInit() {
    this.fetchPracticeLoadOptions();
    this.fetchPMSStatusOptions();
  }

  fetchPracticeLoadOptions() {
    // Make an API call to fetch practice load options
    this.http
      .get<any>(this.getPracticesUrl, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .map((option) => ({
            ...option,
            name: option.practiceCode,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'practiceId'
        ).options = response;
      });
  }

  fetchPMSStatusOptions() {
    // Make an API call to fetch practice load options
    this.http
      .get<any>(this.getStatusUrl, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .map((option) => ({
            ...option,
            name: option.source +" - "+ option.name ,
          }))
        .sort((a,b)=>a.name.localeCompare(b.name));
        response.data = response.data.filter((record) =>
          ACKNOWLEDGEABLE_ERROR_STATUS_CODES.includes(record.code)
        );
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'status'
        ).options = response;
      });
  }

  public routerSubscription: Subscription;

  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/practice-error-logs')
          );
        }
        this.cdr.detectChanges();
      });
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
